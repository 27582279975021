<button
  [disabled]="disabled || loading"
  [type]="type || ''"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-secondary-color': variant === 'info',
    'btn-secondary-gray': variant === 'outline',
    'btn-destructive-primary': variant === 'danger',
    'opacity-50 hover:opacity-50': disabled
  }"
  [class]="'btn btn-md ' + class"
  (click)="onClick.emit($event)"
>
  <div class="flex items-center gap-2">
    <i *ngIf="icon" class="{{ icon }}"></i>
    {{ label }}
    <i *ngIf="loading" class="pi pi-spin pi-spinner order-4"></i>
  </div>
</button>
