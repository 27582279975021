import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlagsDropdownComponent } from './components/dropdowns/flags-dropdown/flags-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { CoreModule } from '../core/core.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { CustomImageComponent } from './components/custom-image/custom-image.component';
import { SidebarModule } from 'primeng/sidebar';
import { HeaderComponent } from './components/header/header.component';
import { Countries } from '../core/models/classes/countries';
import { TranslateModule } from '@ngx-translate/core';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { TreeSelectModule } from 'primeng/treeselect';
import { InputTextModule } from 'primeng/inputtext';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { CapitalizeFirstCasePipe } from '../core/pipes/capitalize-first-case.pipe';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CustomUploadImgComponent } from './components/custom-upload-img/custom-upload-img.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { TabViewComponent } from './components/tabview/tabview.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DragAndDropDirective } from '../core/directives/drag-and-drop.directive';
import { AlphaNumericDirective } from '../core/directives/alpha-numeric.directive';
import { SwiperDirective } from '../core/directives/swiper.directive';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './layouts/auth/login/login.component';
import { PasswordModule } from 'primeng/password';
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { WelcomeComponent } from './components/welcome/welcome.component';
import { DateFormatPipe, DatetimeFormatterModule } from '@soctrip-common/datetime-formatter';

@NgModule({
  declarations: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    FormSectionComponent,
    DragAndDropDirective,
    CapitalizeFirstCasePipe,
    PaginatorComponent,
    CustomUploadImgComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    CalendarComponent,
    SwiperDirective,
    AdminLayoutComponent,
    LoginComponent,
    WelcomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    CoreModule,
    ButtonModule,
    FileUploadModule,
    ImageModule,
    SidebarModule,
    TableModule,
    DialogModule,
    PaginatorModule,
    TreeSelectModule,
    InputTextModule,
    TranslateModule,
    ToastModule,
    SkeletonModule,
    DialogModule,
    CalendarModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    PasswordModule,
    ConfirmDialogModule,
    DatetimeFormatterModule
  ],
  exports: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    CustomImageComponent,
    HeaderComponent,
    TranslateModule,
    FormSectionComponent,
    DragAndDropDirective,
    SentenceCasePipe,
    CapitalizeFirstCasePipe,
    PaginatorComponent,
    CustomUploadImgComponent,
    ToastMessageComponent,
    AlphaNumericDirective,
    TabViewComponent,
    TableEmptyComponent,
    CalendarComponent,
    SwiperDirective,
    DateFormatPipe
  ],
  providers: [Countries],
})
export class SharedModule {}
