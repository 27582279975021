import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'withdrawal-approval',
    title: 'withdrawal-approval',
    icon: SoctripIcons.BANK_NOTE_01,
  },
  {
    title: 'refund-section',
    icon: SoctripIcons.FILE_07,
    children: [
      {
        path: 'refund/refund-request',
        title: 'refund-request',
        icon: SoctripIcons.BANK_NOTE_01,
      },
      {
        path: 'refund/refund-withdrawal-approval',
        title: 'refund-withdrawal-approval',
        icon: SoctripIcons.BANK_NOTE_01,
      },
    ],
  },

  {
    title: 'ledger',
    icon: SoctripIcons.FILE_07,
    children: [
      {
        path: 'ledger/general',
        title: 'general-ledger',
      },
      {
        path: 'ledger/module',
        title: 'ledger-by-module',
      },
    ],
  },

  {
    title: 'adjust-balance',
    path: 'adjust-balance',
    icon: SoctripIcons.SETTINGS_03,
  },

  {
    title: 'balance-management',
    path: 'balance-management',
    icon: SoctripIcons.WALLET_03,
  },
];
