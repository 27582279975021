import { Component, EventEmitter, Input, Output } from '@angular/core';
import { headerEnum } from 'src/app/core/enum/header.enum';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { ConfirmationService } from 'primeng/api';
import { environment } from 'src/environments/environment';

export interface User {
  avatarURL?: string;
  fullName?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ConfirmationService],
})
export class HeaderComponent {
  @Input() isSmallScreen: boolean = false;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();
  @Input() user: User;
  environment = environment;
  shopURL?: string;
  headerEnum = headerEnum;
  appLogo = `../assets/imgs/logo/${environment.APP_LOGO}.svg`;
  constructor(
    private translator: CustomTranslateService,
    private confirmationService: ConfirmationService,
  ) {}

  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }

  confirmLogout() {
    this.confirmationService.confirm({
      key: 'logoutDialog',
      message: 'Are you sure you want to log out?',
      header: 'Logout',
      acceptIcon: 'pi pi-sign-out',
      rejectIcon: 'Cancel',
      acceptLabel: 'Logout',
      rejectLabel: 'Cancel',
      acceptButtonStyleClass: '',
      accept: () => {
        this.handleSignOut();
      },
    });
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleSignOut = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: 'disconnect',
        data: {
          token: localStorage.getItem('accessToken'),
          user_id: localStorage.getItem('userId'),
        },
      };
      frame.contentWindow.postMessage(messageData, '*');
    }
    localStorage.clear();
    window.location.href = `${environment.ADMIN_HUB_URL}/login`;
  };
}
