import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { WelcomeComponent } from './shared/components/welcome/welcome.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'withdrawal-approval',
        loadChildren: () =>
          import(
            './features/withdrawal-approval/withdrawal-approval.module'
          ).then((m) => m.WithdrawalApprovalModule),
      },
      {
        path: 'refund/refund-withdrawal-approval',
        loadChildren: () =>
          import(
            './features/refund-withdrawal-approval/refund-withdrawal-approval.module'
          ).then((m) => m.RefundWithdrawalApprovalModule),
      },
      {
        path: 'refund/refund-request',
        loadChildren: () =>
          import(
            './features/refund-management/refund-management.module'
          ).then((m) => m.RefundManagementModule),
      },
      {
        path: 'ledger',
        loadChildren: () =>
          import('./features/ledger/ledger.module').then((m) => m.LedgerModule),
      },
      {
        path: 'adjust-balance',
        loadChildren: () =>
          import('./features/adjust-balance/adjust-balance.module').then(
            (m) => m.AdjustBalanceModule,
          ),
      },
      {
        path: 'balance-management',
        loadChildren: () =>
          import(
            './features/balance-management/balance-management.module'
          ).then((m) => m.BalanceManagementModule),
      },
      {
        path: '**',
        redirectTo: 'withdrawal-approval',
        pathMatch: 'full',
      },
    ],
  },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
