<ng-container
  *ngIf="uploadedImage || imgThumnail; then uploaded; else upload"
></ng-container>
<ng-template #upload>
  <div
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    *ngIf="!isEditMode"
    class="upload-container border border-gray-300 shadow-sm rounded-lg p-4 flex flex-col items-center gap-3 w-full cursor-pointer"
    [class.shadow]="!uploadedImage"
    (click)="replaceImage()"
  >
    <div
      class="flex p-2 justify-center items-center rounded-[28px] border border-gray-50 bg-gray-100"
    >
      <span class="{{ SoctripIcons.UPLOAD_CLOUD_02 }} text-base"></span>
    </div>
    <div class="flex flex-col gap-1 items-center">
      <span *ngIf="!uploadedImage" class="text-sm font-medium text-gray-700">
        <span class="text-blue-500 mr-1">{{
          approveWithdrawal + "click-to-upload" | translate | sentenceCase
        }}</span>
        {{ approveWithdrawal + "or-drag-and-drop" | translate }}
      </span>
      <ng-container *ngIf="svgType; else elseIsSvgType">
        <span class="text-xs text text-gray-500">{{
          approveWithdrawal + "accepts-svg-png-or-jpg"
            | translate
            | sentenceCase
        }}</span>
      </ng-container>
      <ng-template #elseIsSvgType>
        <span class="text-xs text text-gray-500">{{
          approveWithdrawal + "accepts-png-or-jpg" | translate | sentenceCase
        }}</span>
      </ng-template>
    </div>
    <ng-container
      *ngIf="svgType; then isSvgType; else notSvgTpye"
    ></ng-container>
    <ng-template #isSvgType>
      <input
        #fileInput
        type="file"
        accept="image/svg+xml, image/png, image/jpeg"
        (change)="onFileSelected($event)"
        hidden
      />
    </ng-template>
    <ng-template #notSvgTpye>
      <input
        #fileInput
        type="file"
        accept="image/png, image/jpeg"
        (change)="onFileSelected($event)"
        hidden
      />
    </ng-template>
  </div>
</ng-template>
<ng-template #uploaded>
  <ng-container *ngIf="!isLoading; else elseTemplate">
    <div
      class="flex flex-1 items-center"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      [ngClass]="isStyle ? 'flex-col gap-3' : 'flex-row gap-6 '"
    >
      <ng-container *ngIf="svgType; else isElseTemplate">
        <img
          *ngIf="
            (uploadedImage || imgThumnail) &&
            (getImageThumbnail(uploadedImage) ||
              (imgThumnail && getImageThumbnail(imgThumnail)))
          "
          [src]="
            uploadedImage || (imgThumnail && getImageThumbnail(imgThumnail))
          "
          alt="uploadedImage"
          class="w-[150px] h-[150px] object-cover rounded-lg border border-gray-200 shadow-sm"
        />
      </ng-container>
      <ng-template #isElseTemplate>
        <ng-container *ngIf="isStyle; else elseTemplate">
          <img
            *ngIf="
              (uploadedImage || imgThumnail) &&
              (getImageThumbnail(uploadedImage) ||
                (imgThumnail && getImageThumbnail(imgThumnail)))
            "
            [src]="
              uploadedImage || (imgThumnail && getImageThumbnail(imgThumnail))
            "
            alt="Uploaded Image"
            class="w-[312px] h-[312px] object-contain rounded-lg border border-gray-200 shadow-sm"
          />
        </ng-container>
        <ng-template #elseTemplate>
          <img
            *ngIf="
              (uploadedImage || imgThumnail) &&
              (getImageThumbnail(uploadedImage) ||
                (imgThumnail && getImageThumbnail(imgThumnail)))
            "
            [src]="
              uploadedImage || (imgThumnail && getImageThumbnail(imgThumnail))
            "
            alt="Uploaded Image"
            class="w-[372px] h-[186px] object-cover rounded-lg border border-gray-200 shadow-sm"
          />
        </ng-template>
      </ng-template>

      <div
        *ngIf="!isEditMode && (uploadedImage || imgThumnail)"
        class="image-details flex flex-col gap-6"
      >
        <div *ngIf="!isStyle" class="flex flex-col gap-1">
          <span class="text-sm font-medium text-gray-700">{{ imageName }}</span>
          <span class="text-sm text-gray-500">{{ imageSize }} KB</span>
        </div>
        <div class="flex flex-row gap-2">
          <button
            (click)="replaceImage()"
            class="btn btn-sm btn-secondary-gray"
          >
            {{ approveWithdrawal + "replace" | translate | sentenceCase }}
          </button>
          <button (click)="removeImage()" class="btn btn-sm btn-secondary-gray">
            {{ approveWithdrawal + "remove" | translate | sentenceCase }}
          </button>
        </div>
      </div>
      <ng-container
        *ngIf="svgType; then isSvgType; else notSvgTpye"
      ></ng-container>
      <ng-template #isSvgType>
        <input
          #fileInput
          type="file"
          accept="image/svg+xml, image/png, image/jpeg"
          (change)="onFileSelected($event)"
          hidden
        />
      </ng-template>
      <ng-template #notSvgTpye>
        <input
          #fileInput
          type="file"
          accept="image/png, image/jpeg"
          (change)="onFileSelected($event)"
          hidden
        />
      </ng-template>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <ng-container
      *ngIf="isStyle; then thenTemplate; else elseTemplate"
    ></ng-container>
    <ng-template #thenTemplate>
      <div *ngIf="isLoading" class="grid grid-cols-1 gap-3">
        <div class="p-7">
          <p-progressBar
            mode="indeterminate"
            [style]="{ height: '10px' }"
          ></p-progressBar>
        </div>
        <div class="grid grid-cols-1 items-center gap-6 p-7">
          <div class="grid grid-cols-2 justify-start gap-2 w-max">
            <p-skeleton class="w-[100px]"></p-skeleton>
            <p-skeleton class="w-[100px]"></p-skeleton>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #elseTemplate>
      <div *ngIf="isLoading" class="grid grid-cols-2 gap-3">
        <div class="p-7">
          <p-progressBar
            mode="indeterminate"
            [style]="{ height: '10px' }"
          ></p-progressBar>
        </div>
        <div class="grid grid-cols-1 gap-6 p-7">
          <div class="grid grid-cols-1 gap-1">
            <p-skeleton class="w-[200px]"></p-skeleton>
            <p-skeleton class="w-[100px]"></p-skeleton>
          </div>
          <div class="grid grid-cols-2 justify-start gap-2 w-max">
            <p-skeleton class="w-[100px]"></p-skeleton>
            <p-skeleton class="w-[100px]"></p-skeleton>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-template>
