<div class="h-full flex gap-2 justify-between items-center px-6 bg-blue-600">
  <span class="flex items-center gap-2">
    <i
      *ngIf="isSmallScreen"
      class="pi pi-bars p-1 cursor-pointer mr-1 text-white"
      (click)="openSidebar.emit(true)"
    ></i>
    <a [href]="shopURL" class="flex items-center gap-2 cursor-pointer">
      <img
        class="inline-block w-auto h-[1.875rem] object-contain object-center"
        [src]="appLogo"
        alt="SoctripApp"
      />
    </a>
    <h2 class="text-xl font-bold text-white">{{ environment.APP_TITLE }}</h2>
    <div class="text-white text-lg font-medium leading-7">
      {{ headerEnum.FINANCE }}
    </div>
  </span>

  <div class="flex items-center gap-4 lg:gap-6">
    <app-flags-dropdown
      (languageChange)="changeLanguage($event)"
    ></app-flags-dropdown>

    <div class="relative cursor-pointer">
      <i class="pi pi-bell !text-xl text-white"></i>
      <span
        class="absolute w-2 h-2 bg-orange-dark-500 rounded-full left-3 top-[0.125rem]"
      ></span>
    </div>

    <div
      (click)="menu.toggle($event)"
      class="flex items-center gap-3 cursor-pointer"
    >
      <img
        class="inline-block w-[1.875rem] h-[1.875rem] object-contain object-center rounded-full"
        [src]="user?.avatarURL"
        onerror="this.src='assets/imgs/default/avatar.webp'"
      />
      <div *ngIf="user?.fullName" class="grid text-sm font-semibold text-white">
        <span>{{ user.fullName }}</span>
      </div>
    </div>
    <p-overlayPanel #menu>
      <div class="-mx-4 -my-3">
        <div
          class="flex items-center gap-2 text-orange-dark-600 min-w-[200px] px-4 py-2 cursor-pointer hover:bg-gray-100"
          (click)="confirmLogout()"
        >
          <i class="sctr-icon-log-out-01"></i>
          <div class="text-sm">Logout</div>
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>

<p-confirmDialog
  key="logoutDialog"
  [style]="{ width: '40vw', 'max-width': '400px' }"
  rejectButtonStyleClass="p-button-outlined"
></p-confirmDialog>
